import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import KioskButton from '../theme/Button';
import QRCode from '../assets/Doctegrity.png';

export default function SimpleDialog(props) {
    const { handleClose, open } = props;
    const [modalInfo, setModalInfo] = React.useState('init');

    const handleLocalClose = () => {
        handleClose();
        setTimeout(() => {
            setModalInfo('init');
        }, 500);
    };

    return (
        <Dialog onClose={handleLocalClose} open={open} fullWidth={true} maxWidth={'md'}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    zIndex: 20,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <DialogTitle sx={{ color: 'black' }}>
                        {modalInfo === 'init' && (
                            <FormattedMessage
                                id="selectOption"
                                defaultMessage="Please select an option"
                            />
                        )}
                    </DialogTitle>
                    <IconButton aria-label="delete" onClick={handleLocalClose} size="large">
                        <CancelIcon style={{ width: 40, height: 40 }} />
                    </IconButton>
                </Box>
                {modalInfo === 'init' && (
                    <Box sx={{ width: 'calc(100% - 60px)', margin: '0 auto' }}>
                        <br />
                        <div style={{ width: '100%' }}>
                            <KioskButton
                                variant="outlined"
                                iconbgcolor="#F67C1E"
                                size="large"
                                startIcon={<MedicalServicesIcon />}
                                onClick={() => setModalInfo('free-check')}
                            >
                                Free Check
                            </KioskButton>
                        </div>
                        <br />
                        <div style={{ width: '100%' }}>
                            <KioskButton
                                variant="outlined"
                                iconbgcolor="#2EB777"
                                size="large"
                                startIcon={<MedicalServicesIcon />}
                                //href="https://app.doctegrity.com/"
                                onClick={() => setModalInfo('doctegrity')}
                            >
                                Speak to a Medical Professional
                            </KioskButton>
                        </div>
                        <br />
                        <br />
                    </Box>
                )}
                {modalInfo === 'free-check' && (
                    <Box
                        sx={{
                            width: 'calc(100% - 60px)',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <br />
                        <div style={{ width: '48%' }}>
                            <div>
                                <img src={QRCode} style={{ width: '100%' }}></img>
                                <p>
                                    Doctegrity parterns with the best medical professionals in the
                                    industry. Worlwidetechconnections is proud to offer this service
                                </p>
                            </div>
                        </div>
                        <br />
                        <div style={{ width: '48%' }}>
                            <div>
                                <video
                                    width="240"
                                    height="480"
                                    controls
                                    style={{ width: '100%' }}
                                    autoPlay
                                >
                                    <source
                                        src="https://ui.worldwidetechconnections.com/video.mp4"
                                        type="video/mp4"
                                    ></source>
                                </video>
                            </div>
                        </div>
                        <br />
                        <br />
                    </Box>
                )}
                {modalInfo === 'doctegrity' && (
                    <Box
                        sx={{
                            width: 'calc(100% - 60px)',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <br />
                        <div style={{ width: '380px', textAlign: 'center' }}>
                            <div>
                                <img
                                    src="https://www.doctegrity.com/wp-content/uploads/2023/01/Logo-1.svg"
                                    alt="Doctegrity logo"
                                />
                                <p>Sign up and Download the app</p>
                                <img
                                    src={QRCode}
                                    style={{ width: '100%' }}
                                    alt="Doctegrity QR Code"
                                ></img>
                            </div>
                        </div>
                        <br />
                    </Box>
                )}
            </Box>
        </Dialog>
    );
}
