import * as React from 'react';
import axios from 'axios';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material/styles';
import HealthWellness from './views/HealthWellness';
import FreeNationWide from './views/FreeNationWide';
import PhoneFreeNation from './views/PhoneFreeNation';
import FreeCalling from './views/FreeCalling';
import MultilingualCalling from './views/MultilingualCalling';
import theme from './theme';
import messages from './utils/intl';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import IndexApp from './views/index';
import { StateProvider, useStateValue } from './store';
import { sortLanguages } from './utils/languages';

import Twillio from './utils/twillio';
import AirlinesMulticalling from './views/AirlinesMulticalling';
import AirlineLanguage from './views/AirlineLanguage';
import AirportMap from './views/AirportMap';
import CustomerSurvey from './views/CustomerSurvey';

const MINUTES_INTERVAL_CHECK_VERSION = 5;
const MILISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MILISECONDS_IN_INTERVAL_CHECK_VERSION = MINUTES_INTERVAL_CHECK_VERSION * SECONDS_IN_MINUTE * MILISECONDS_IN_SECOND;


function App() {
    const [state, setState] = React.useState(null);
    const [currentVersion, setCurrentVersion] = React.useState(null);

    const checkVersion = async () => {
        try {
            const response = await fetch('/version.json?t=' + new Date().getTime());
            const data = await response.json();

            if (currentVersion && currentVersion !== data.version) {
                window.location.reload();
            }
            
            setCurrentVersion(data.version);
        } catch (error) {
            console.error('Error checking version:', error);
        }
    };

    React.useEffect(() => {
        async function fetchData() {
            const { data: languages } = await axios.get(
                'https://core.worldwidetechconnections.com/languages',
            );
            // sort languages
            const sortedLanguages = sortLanguages(languages);
            const languagesMapped = sortedLanguages.map((item, index) => ({
                id: index,
                label: item.name,
                value: item.code,
                img: item.flag,
                services: item.services,
            }));
            setState(languagesMapped);
        }
        Twillio.connect();
        fetchData();

        // Verify version every 5 minutes to check if the version is updated and reload the page if it is
        const interval = setInterval(checkVersion, MILISECONDS_IN_INTERVAL_CHECK_VERSION);
        checkVersion();

        return () => {
            clearInterval(interval);
        };
    }, [currentVersion]);

    if (!state) return null;

    return (
        <StateProvider languagesData={state}>
            <ProviderIntl>
                <ThemeProvider theme={theme}>
                    <React.StrictMode>
                        <BrowserRouter>
                            <Routes>
                                <Route index path="/" element={<IndexApp currentVersion={currentVersion} />} />
                                <Route path="/free-nation" element={<FreeNationWide />} />
                                <Route path="/phone-free-nation" element={<PhoneFreeNation />} />
                                <Route path="/free-calling" element={<FreeCalling />} />
                                <Route path="/health-wellness" element={<HealthWellness />} />
                                <Route path="/airport-map" element={<AirportMap />} />
                                <Route path="/customer-survey" element={<CustomerSurvey />} />
                                <Route
                                    path="/multilingual-calling"
                                    element={<MultilingualCalling />}
                                />
                                <Route
                                    path="/multilingual-airlines"
                                    element={<AirlinesMulticalling />}
                                />
                                <Route path="/airline-language" element={<AirlineLanguage />} />
                            </Routes>
                        </BrowserRouter>
                    </React.StrictMode>
                </ThemeProvider>
            </ProviderIntl>
        </StateProvider>
    );
}

const ProviderIntl = (props) => {
    const [{ languages }, dispatch] = useStateValue();

    React.useEffect(() => {
        inactivityTime();
    }, [languages.languageIntl]);

    const safeLocale = React.useMemo(() => {
        try {
            new Intl.NumberFormat(languages.languageIntl);
            return languages.languageIntl;
        } catch (e) {
            return 'english';
        }
    }, [languages.languageIntl]);

    var inactivityTime = function () {
        var time;
        window.onload = resetTimer;
        // DOM Events
        document.onclick = resetTimer;

        function defaultLanguage() {
            if (languages.languageIntl !== 'english') {
                return dispatch({
                    type: 'SET_LANGUAGE',
                    payload: { lng: 'english-united-states', intl: 'english' },
                });
            }
        }

        function resetTimer() {
            clearTimeout(time);
            // default language after 60 seconds
            time = setTimeout(defaultLanguage, 60000);
        }
    };

    return (
        <IntlProvider
            messages={messages[languages.languageIntl]}
            locale={safeLocale}
            defaultLocale="english"
        >
            {props.children}
        </IntlProvider>
    );
};

export default App;
